import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.teal, // #009688
        secondary: colors.brown.darken4, // #3E2723
        accent: colors.lime.lighten1, // #D4E157
        error: colors, // #FFC107
      },
      dark: {},
    },
  },
})
