<script>
  import Heading from './Heading'
  import Header from '@/mixins/heading'

  export default {
    name: 'BaseNumber',

    extends: Heading,

    mixins: [Header],

    props: {
      size: {
        type: String,
        default: 'h1',
      },
      mobileSize: {
        type: String,
        default: 'display-1',
      },
      tag: {
        type: String,
        default: 'h1',
      },
    },
  }
</script>
