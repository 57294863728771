<template lang="pug">
v-fade-transition(mode="out-in")
  router-view
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: '%s | Sincronizate',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },

    data () {
      return {
        name: '',
      }
    }
  }
</script>
