import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  state: {
    token: null,
    user: null,
    isUserLoggedIn: false,
    arboles: 2,
    descripcionPlan: 'BosqueCoomeva',
    personasXPaquete: 0,
    datePlan: '',
    order: {
      additionalValues: {
        TX_VALUE: {
          value: 0,
        },
      },
    },

    // huella
    cocinar: [
      { No: 'Electricidad', value: 3 },
      { No: 'Gas', value: 1 },
      { No: 'Las dos', value: 2 },
    ],
    iluminacion: [
      { No: 'Ahorrador', value: 3 },
      { No: 'Led', value: 1 },
      { No: 'Los dos tipos', value: 2 },
    ],
    desconectas: [
      { No: 'Si', value: 1 },
      { No: 'No', value: 2 },
    ],
    tipo: [
      { No: 'Caminas', value: 1 },
      { No: 'Bicicleta', value: 2 },
      { No: 'Motocicleta', value: 5 },
      { No: 'Motocicleta eléctrica', value: 4 },
      { No: 'Automóvil', value: 7 },
      { No: 'Automóvil eléctrica', value: 6 },
      { No: 'Público', value: 3 },
    ],
    frecuencia: [
      { No: 'Nunca', value: 1 },
      { No: 'Una vez al año', value: 2 },
      { No: 'Dos veces al año', value: 3 },
      { No: 'Tres veces al año', value: 4 },
      { No: 'Más de tres veces al año', value: 5 },
    ],
    alimentacion: [
      { No: 'Omnivora', value: 3 },
      { No: 'Vegana', value: 1 },
      { No: 'Vegetariana', value: 2 },
    ],
    ropa: [
      { No: 'Natural (Algodón, Seda)', value: 1 },
      { No: 'Sintético (Poliester, rayón y otros)', value: 3 },
      { No: 'Te da igual', value: 2 },
    ],
    vivienda: [
      { No: 'Ecológicas (barro, madera)', value: 1 },
      { No: 'Tendinoso, Prefabricada', value: 2 },
      { No: 'Ladrillo y Cemento', value: 3 },
    ],
    celular: [
      { No: 'Cada año', value: 4 },
      { No: 'Cada dos años', value: 3 },
      { No: 'Más de dos años', value: 1 },
    ],
    bosque: [
      { No: 'Cada semana', value: 1 },
      { No: 'Cada mes', value: 2 },
      { No: 'Cada año', value: 3 },
      { No: 'Nunca', value: 4 },
    ],
    parques: [
      { No: 'Si', value: 2 },
      { No: 'No', value: 3 },
      { No: 'Vivo en el campo', value: 1 },
    ],
    reciclas: [
      { No: 'Si', value: 1 },
      { No: 'No', value: 2 },
    ],

    p1: null,
    p2: null,
    p3: null,
    p4: null,
    p5: null,
    p6: null,
    p7: null,
    p8: null,
    p9: null,
    p10: null,
    p11: null,
    totalHuella: 23,

    // Gift
    gift: [
      { motive: 'Amistad', value: 'amistad' },
      { motive: 'Cumpleaños', value: 'cumpleaños' },
      { motive: 'Partida de un ser querido', value: 'treebuto' },
      { motive: 'Matrimonio', value: 'matrimonio' },
      { motive: 'Nacimiento', value: 'nacimiento' },
      { motive: 'Fecha especial', value: 'fechaespecial' },
    ],

    gifter: '',
    emailGifter: '',
    motivo: '',
  },
  mutations: {
    increment(state) {
      if (state.arboles <= 100) {
        state.arboles++
        state.order.additionalValues.TX_VALUE.value = state.arboles * 10500
      }
    },
    decrement(state) {
      if (state.arboles > 1) {
        state.arboles--
        state.order.additionalValues.TX_VALUE.value = state.arboles * 10500
      }
    },
    changeName(state, newName) {
      state.descripcionPlan = newName
    },
    changedatePlan(state, date) {
      state.datePlan = date
    },
    setToken(state, token) {
      state.token = token
      state.isUserLoggedIn = !!token
    },
    setUser(state, user) {
      state.user = user
    },
    updateTxValue(state, value) {
      state.order.additionalValues.TX_VALUE.value = value
    },
    updateDescripcionPlan(state, value) {
      state.descripcionPlan = value
    },
    updateTotalHuella(state, value) {
      state.totalHuella = value
    },
  },
  getters: {
    txValue: state => {
      return state.order.additionalValues.TX_VALUE.value
    },
    calculatedValue: (state) => state.arboles * 10500,
    personasXPaquete: (state) => {
      if (state.descripcionPlan === 'Básico') return 30
      if (state.descripcionPlan === 'Restaura') return 1
      if (state.descripcionPlan >= 13 && state.descripcionPlan <= 15) return 2
      if (state.descripcionPlan >= 16 && state.descripcionPlan <= 20) return 3
      if (state.descripcionPlan >= 21) return 4
      return 5
    },
    descripcionPlan: (state) => {
      if (state.arboles >= 1 && state.arboles <= 10) return 'Coomeva'
      if (state.arboles >= 11 && state.arboles <= 20) return 'Coomeva'
      if (state.arboles >= 21) return 'Coomeva'
      return state.descripcionPlan
    },
    totalHuella: () => 300,
  },
  actions: {
    setdescripcionPlan({ commit }, newName) {
      commit('changeName', newName)
    },
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    updateDescripcionPlan({ commit, getters }) {
      commit('updateDescripcionPlan', getters.descripcionPlan)
    },
    updateTotalHuella({ commit, getters }) {
      commit('updateTotalHuella', getters.totalHuella)
    },
    updateValue({ commit, state }) {
      const value = state.arboles * 10500
      commit('updateTxValue', value)
    }  },
})

export default store
