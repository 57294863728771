<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        title="Mantente en contacto"
        space="6"
      >
        Llamanos, escribenos, tus opiniones son importante para nostros.
      </base-info-card>

      <base-business-contact dense />
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessInfo',

    props: { dark: Boolean },

  }
</script>
