<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          cols="12"
          class="mb-4"
        >
          <base-icon :color="color">
            {{ icon }}
          </base-icon>
        </v-col>

        <v-col
          v-if="title || subtitle"
          :cols="callout ? 9 : 12"
        >
          <base-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="1"
          />

          <base-number
            v-if="number"
            :title="number"
            class="display-3"
          />

          <base-title
            :title="title"
            :color="color"
            class="text-uppercase"
            space="1"
          />

          <base-divider :color="color" />

          <base-body
            v-if="text || $slots.default"
            :text="text"
            space="6"
          >
            <slot />
          </base-body>

          <div class="px-8 mb-12">
            <template v-for="(feature, i) in features">
              <div
                :key="feature"
                class="my-3"
                v-text="feature"
              />

              <v-divider
                v-if="i + 1 < features.length"
                :key="`divider-${i}`"
              />
            </template>
          </div>
        </v-col>

        <v-col
          v-if="callout"
          cols="2"
        >
          <div
            class="display-3 grey--text text--lighten-4 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseInfoCard',

    mixins: [Heading],

    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      subtitle: String,
      features: {
        type: Array,
        default: () => ([]),
      },
      text: String,
      title: String,
      number: String,
    },
  }
</script>
