<template>
  <v-app>
    <home-view />
    <home-footer />
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      HomeFooter: () => import('./Footer'),
      HomeView: () => import('./View'),
    },
  }
</script>
