import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../components/layout/home/Index.vue'
import Thanks from '../views/thanks.vue'
import Tyc from '../views/tyc.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        name: 'Inicio',
        component: () => import('@/views/home/Index.vue'),
      },
      {
        path: '/empresas',
        name: 'Empresas',
        component: () => import('@/views/empresas/Index.vue'),
      },
      {
        path: 'huella',
        name: 'Mide tu huella',
        component: () => import('@/components/formHuella/HogarHuella.vue'),
      },
      {
        path: 'privacidad',
        name: 'privacidad',
        component: () => import('@/views/privacidad.vue'),
      },
      {
        path: 'Siembra',
        name: 'Siembra árboles',
        component: () => import('@/views/siembra/Index.vue'),
      },
      {
        path: 'sincronizate',
        name: 'sincronizate',
        component: () => import('@/components/formHuella/HogarHuella.vue'),
      },
      // Huella
      {
        path: 'transporte',
        name: 'Transporte',
        component: () => import('@/components/formHuella/Transporte.vue'),
      },
      {
        path: 'consumo',
        name: 'Consumo',
        component: () => import('@/components/formHuella/ConsumoHuella.vue'),
      },
      {
        path: 'vida',
        name: 'Vida',
        component: () => import('@/components/formHuella/Vida.vue'),
      },
      {
        path: 'total',
        name: 'Total',
        component: () => import('@/views/sincronizate/Index.vue'),
      },
      {
        path: 'gift',
        name: 'Regala',
        component: () => import('@/views/regala/Index.vue'),
      },
      {
        path: 'contact-us',
        name: 'Contacto',
        component: () => import('@/views/contact-us/Index.vue'),
        meta: { src: require('@/assets/contacto.jpg') },
      },
      {
        path: 'confirmacionBanco',
        name: 'confirmacionBanco',
        component: () => import('@/views/confirmacion/Index.vue'),
      },
    ],
  },
  { path: '/thanks', name: 'thanks', component: Thanks },
  { path: '/terminosycondiciones', name: 'tyc', component: Tyc },
  { path: '*', redirect: 'home' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes,
})

export default router
