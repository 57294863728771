import axios from 'axios'

const URLAPP = 'https://kimu.azurewebsites.net/amateaApp'

// PRO
const URL = 'https://dendros-payu2-b5a2etg8ctfkfsdw.eastus-01.azurewebsites.net/Payu'

// Payu
// const URL = 'https://comercio.amatea.org/Payu'

// DEV
// const URL = 'http://localhost:8081/Payu'
// const URL = 'http://localhost:8080/amateaOrg'
// const URLAPP = 'http://localhost:8080/amateaAPP'

// se conecta a misiembra
export const HTTP = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

export const HTTP2 = axios.create({
  // baseURL: 'https://kimu.amatea.org/amateaApp',
  baseURL: URLAPP,
  headers: {
    'Content-Type': 'application/json',
  // eslint-disable-next-line
    'Authorization': 'Bearer ' + window.localStorage.token,
  },
})

const dev = {
  server: URL,
}

export default dev
